import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { Dropdown } from "react-bootstrap"

const Header = () => {
  const logout = () => {
    localStorage.clear()
  }

  useEffect(() => {
    $("[data-trigger]").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      var offcanvas_id = $(this).attr("data-trigger");
      $(offcanvas_id).toggleClass("show");

    });

    $(".btn-aside-minimize").on("click", function () {
      if (window.innerWidth < 768) {
        $("body").removeClass("aside-mini");
        $(".navbar-aside").removeClass("show");
      } else {
        $("body").toggleClass("aside-mini");
      }
    });
  }, []);

  const Admin=  JSON.parse(localStorage.getItem("loginAdmin"));

  return (
    <header className="main-header navbar justify-content-end">
      <div className="col-nav">
        <ul className="nav">
           <li className="nav-item">
           {Admin?.name}
          </li>
          
          <Dropdown>
            <li class="nav-item dropdown user_drpopdown">
              <Dropdown.Toggle className="disp ">
                <label className="user_image">
                  <img className="rounded-circle img-fluid"
                    src='/image/favicon.png' alt=""></img>
                </label>
              </Dropdown.Toggle>
              <Dropdown.Menu >
                <Link to="/" class="dropdown-item" onClick={logout}>Sign Out</Link>
              </Dropdown.Menu>
            </li>
          </Dropdown>
        </ul>
      </div>
    </header>
  );
};

export default Header;
