import logo from '../assets/image/halal-logo.png'

export default function LoadScreen(){
return(
    <div className='loading'>
    <img src={logo} alt="logo"/>
   
    <p className='mt-2'>Please wait we are fetching your data</p>
    </div>
)
}




