import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const Guest = ({ children }) => {
  const navigate = useNavigate();
  const token = (localStorage.getItem("token"))

useEffect(() => {
  if (token) {
    navigate('/dashboard')
 }
}, [token]);


  return <>{children}</>;
};
export default Guest;
