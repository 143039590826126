import React, { Suspense, Fragment, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Authenticate from "./component/Gurd/Authenticate"
import LoadScreen from './component/loaderScreen';
import SidebarLayout from './layouts/SidebarLayout'
import Guest from './component/Gurd/Guest';

export function RenderRout() {
  
    return (
      <>
        <Router>
          <Suspense fallback={<div><LoadScreen/></div>}
          >
              <Routes>
              {routes?.map((route, i) => {
                const Guard = route?.guard || Fragment;
                const Layout = route?.layout || Fragment;
                const Component = route?.element;
                return (
                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    element={
                      <Guard>
                        <Layout >
                          <Component />
                          </Layout>
                      </Guard>
                    }
                  />
                );
              })}
            </Routes>
          </Suspense>
        </Router>
         </>
    )
  
  }

  const routes = [
  
    {
      exact: true,
      guard: Guest,
      path: '/',
      element: lazy(() => import('./component/Admin/Login'))
    },

    {
      exact: true,
      path: '*',
      element: lazy(() => import('./component/Notfound/index'))
    },
   
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/users',
      element: lazy(() => import('./component/Users/index'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/users-view',
      element: lazy(() => import('./component/Users/userView'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/users-edit',
      element: lazy(() => import('./component/Users/userEdit'))
    },

    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/restaurant',
      element: lazy(() => import('./component/Restaurant/index'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/restaurant/:id/:type',
      element: lazy(() => import('./component/Restaurant/index'))
    },
    
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/restaurant-create',
      element: lazy(() => import('./component/Restaurant/RestaurantCreate'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/restaurant-view',
      element: lazy(() => import('./component/Restaurant/RestaurantView'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/restaurant-edit',
      element: lazy(() => import('./component/Restaurant/RestaurantEdit'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/restaurant-product/:id',
      element: lazy(() => import('./component/Restaurant/ProductView'))
    },
    
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/restaurant-rating',
      element: lazy(() => import('./component/Rating'))
    },

    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/discount',
      element: lazy(() => import('./component/Discount'))
    },
    
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/discount-view',
      element: lazy(() => import('./component/Discount/DiscountView'))
    },

    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/discount-edit',
      element: lazy(() => import('./component/Discount/DiscountEdit'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/discount-create',
      element: lazy(() => import('./component/Discount/DiscountCreate'))
    },

    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/category',
      element: lazy(() => import('./component/Category'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/category-view',
      element: lazy(() => import('./component/Category/CategoryView'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/category-edit',
      element: lazy(() => import('./component/Category/CategoryEdit'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/category-create',
      element: lazy(() => import('./component/Category/CategoryCreate'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/upload/ingredient',
      element: lazy(() => import('./component/AppIngredients'))
    },
 
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/ingredient',
      element: lazy(() => import('./component/Ingredients'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/ingredient-subcategory',
      element: lazy(() => import('./component/Ingredients/subCategoryList'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/ingredient-create',
      element: lazy(() => import('./component/Ingredients/IngredientsCreate'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/ingredient-subcategory-create',
      element: lazy(() => import('./component/Ingredients/AddSubcategory'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/ingredient-edit',
      element: lazy(() => import('./component/Ingredients/IngredientsEdit'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/dashboard',
      element: lazy(() => import('./component/Dashboard'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/banner',
      element: lazy(() => import('./component/Banner'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/banner-edit',
      element: lazy(() => import('./component/Banner/BannerEdit'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/banner-create',
      element: lazy(() => import('./component/Banner/BannerCreate'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/notification',
      element: lazy(() => import('./component/Notification'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/notification-create',
      element: lazy(() => import('./component/Notification/NotificationCreate'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/cms',
      element: lazy(() => import('./component/Cms'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/cms-view',
      element: lazy(() => import('./component/Cms/CmsView'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/cms-edit',
      element: lazy(() => import('./component/Cms/CmsEdit'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/cms-create',
      element: lazy(() => import('./component/Cms/CmsCreate'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/product',
      element: lazy(() => import('./component/Product'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/res-upload',
      element: lazy(() => import('./component/UserUploadResturents/Index'))
    },
   
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/product-view',
      element: lazy(() => import('./component/Product/ProductView'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/product-add',
      element: lazy(() => import('./component/Product/AddProduct'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/product-edit',
      element: lazy(() => import('./component/Product/ProductEdit'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/restaurant-reports-claims',
      element: lazy(() => import('./component/ReportAndClaim/index'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/clam-list',
      element: lazy(() => import('./component/ReportAndClaim/ClaimList'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/restaurant-report-view',
      element: lazy(() => import('./component/ReportAndClaim/ReportView'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/restaurant-report-edit',
      element: lazy(() => import('./component/ReportAndClaim/ReportEdit'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/country',
      element: lazy(() => import('./component/Country'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/add-country',
      element: lazy(() => import('./component/Country/CountryAdd'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/edit-country',
      element: lazy(() => import('./component/Country/CountryUpdate'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/report',
      element: lazy(() => import('./component/Report/ResturentReport'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/admin-banner',
      element: lazy(() => import('./component/Report/BannerReport'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/menu-report',
      element: lazy(() => import('./component/Report/MenuReport'))
    },

    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/cupon-report',
      element: lazy(() => import('./component/Report/CoupenReport'))
    },
    
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/fav-resturent',
      element: lazy(() => import('./component/Report/FavroteResturentReport'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/diet-report',
      element: lazy(() => import('./component/Report/DietReport'))
    },
    
    
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/report-view/:id/:date/:endDate/:type',
      element: lazy(() => import('./component/Report/ReportView'))
    },

    {
      layout:SidebarLayout,
      guard:Authenticate,
      exact:true,
      path:'/feedback-list',
      element:lazy(()=>import('./component/UserFeedback/UserFeedbackList'))
},
{
  layout:SidebarLayout,
  guard:Authenticate,
  exact:true,
  path:'/contact-us-list',
  element:lazy(()=>import('./component/UserContactUs/Index'))
},

  {
    layout:SidebarLayout,
    guard:Authenticate,
    exact:true,
    path:'/business-claim-list',
    element:lazy(()=>import('./component/BusinessClaim/Index'))
  },
  {
    layout:SidebarLayout,
    guard: Authenticate,
    exact: true,
    path: "/category/subcategory/:categoryId",
    element: lazy(() => import("./component/Subcategory/Index")),
  },
  {
    layout:SidebarLayout,
    guard: Authenticate,
    exact: true,
    path: "/category/subcategory-create/:categoryId",
    element: lazy(() =>import("./component/Subcategory/SubCategoryCreate")),
  },
  {
    layout:SidebarLayout,
    guard: Authenticate,
    exact: true,
    path: "/category/subcategory-edit/:categoryId",
    element: lazy(() =>import("./component/Subcategory/SubCategoryEdit")),
  },
  {
    layout:SidebarLayout,
    guard:Authenticate,
    exact:true,
    path:"/feature-restaurant-create-update/:id",
    element:lazy(()=>import("./component/RestaurantFeatures/CreateUpdate"))
  },
  {
    layout:SidebarLayout,
    guard:Authenticate,
    exact:true,
    path:"/feature-restaurant-create-update",
    element:lazy(()=>import("./component/RestaurantFeatures/CreateUpdate"))
  },
  {
    layout:SidebarLayout,
    guard:Authenticate,
    exact:true,
    path:"/feature-restaurant",
    element:lazy(()=>import("./component/RestaurantFeatures/Index"))
  },
  {
    layout:SidebarLayout,
    guard:Authenticate,
    exact:true,
    path:"/user-report",
    element:lazy(()=>import("./component/ReportListing/ReportListing"))
  }
  ,{
    layout:SidebarLayout,
    guard:Authenticate,
    exact:true,
    path:"/business-list",
    element:lazy(()=>import("./component/BusinessListing/BusinessListing"))
  }
]