import React, { useState } from "react";
import Header from "./Header";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/image/halal-logo.png";

const SidebarLayout = (props) => {
  const [openMenu, setOpenMenu] = useState("");

  const toggleMenu = (menuId) => {
    setOpenMenu(openMenu === menuId ? "" : menuId);
  };

  const location = window.location;
  const user = location.pathname.match(/^\/users/) ? "active" : "";
  const restaurant = location.pathname.match(/^\/restaurant/) ? "active" : "";
  const category = location.pathname.match(/^\/category/) ? "active" : "";
  const discount = location.pathname.match(/^\/discount/) ? "active" : "";
  const banner = location.pathname.match(/^\/banner/) ? "active" : "";
  const notification = location.pathname.match(/^\/notification/)
    ? "active"
    : "";
  const ingredient = location.pathname.match(/^\/ingredient/) ? "active" : "";
  const appingredient = location.pathname.match(/^\/upload/) ? "active" : "";
  const cms = location.pathname.match(/^\/cms/) ? "active" : "";
  const product = location.pathname.match(/^\/product/) ? "active" : "";
  const uploadres = location.pathname.match(/^\/res-upload/) ? "active" : "";
  const country = location.pathname.match(/^\/country/) ? "active" : "";
  const report = location.pathname.match(/^\/report/) ? "active" : "";
  const reports = location.pathname.match(/^\/admin-banner/) ? "active" : "";
  const cuisine = location.pathname.match(/^\/menu-report/) ? "active" : "";
  const cupon = location.pathname.match(/^\/cupon-report/) ? "active" : "";
  const favreports = location.pathname.match(/^\/fav-resturent/)
    ? "active"
    : "";
  const diet = location.pathname.match(/^\/diet-report/) ? "active" : "";
  const clam = location.pathname.match(/^\/clam-list/) ? "active" : "";
  const feedback = location.pathname.match(/^\/feedback-list/) ? "active" : "";
  const contactUs = location.pathname.match(/^\/contact-us-list/)
    ? "active"
    : "";
  const businessClaims = location.pathname.match(/^\/business-claim-list/)
    ? "active"
    : "";
  const restauarntFeatures = location.pathname.match(/^\/feature-restaurant/)
    ? "active"
    : "";
  const userreport = location.pathname.match(/^\/user-report/) ? "active" : "";
  const businessList = location.pathname.match(/^\/business-list/)
    ? "active"
    : "";

  return (
    <>
      <Header />
      <div className="Adjust">
        <div className="Sidebar">
          <aside
            className="navbar-aside navbar-aside_custom"
            id="offcanvas_aside"
          >
            <div className="aside-top border-bottom">
              <Link to="/users" className="brand-wrap">
                <div class="admin_logo">
                  <img src={logo} class="img-fluid login_logo" alt="#" />
                </div>
              </Link>
            </div>

            <nav className="n-bar-cutom">
              <Link className="nav-link text-white mt-4" to="/dashboard">
                <i className="fa fa-database picon" />
                Dashboard
              </Link>

              {/* Reusable Dropdown Menu */}
              {renderDropdownMenu("GM002", "Restaurant Management", [
                {
                  label: "Manage Restaurants",
                  href: "/restaurant",
                  active: restaurant,
                },
                {
                  label: "Restaurant Categories",
                  href: "/category",
                  active: category,
                },
                {
                  label: "Restaurant Features",
                  href: "/feature-restaurant",
                  active: restauarntFeatures,
                },
              ])}

              {renderDropdownMenu("GM003", "Claims", [
                {
                  label: "Restaurant Claims",
                  href: "/clam-list",
                  active: clam,
                },
                {
                  label: "Business registration - App",
                  href: "/business-claim-list",
                  active: businessClaims,
                },
                {
                  label: "Business registration – Website",
                  href: "/business-list",
                  active: businessList,
                },
                {
                  label: "Recommended Spots - App",
                  href: "/res-upload",
                  active: uploadres,
                },
                // { label: "Recommended Spots – Website", href: "/dashboard/manage_stock" },
              ])}

              {renderDropdownMenu("GM004", "Users and Feedback", [
                { label: "Manage App Users", href: "/users", active: user },
                {
                  label: "Manage Users Feedback",
                  href: "/feedback-list",
                  active: feedback,
                },
                {
                  label: "Favorite Restaurants",
                  href: "/fav-resturent",
                  active: favreports,
                },
              ])}

              {renderDropdownMenu("GM005", "Products and Ingredients", [
                { label: "Manage Products", href: "/product", active: product },
                {
                  label: "Manage Ingredients",
                  href: "/ingredient",
                  active: ingredient,
                },
               
                 {
                  label: "Manage Upload Ingredients",
                  href: "/upload/ingredient",
                  active: appingredient,
                },
              ])}
              {renderDropdownMenu("GM006", "Marketing and Promotions", [
                { label: "Discounts / Coupons", href: "/discount", active: discount },
                {
                  label: "Manage Banners",
                  href: "/banner",
                  active: banner,
                },
               
                 {
                  label: "News / Announcements",
                  href: "/notification",
                  active: notification,
                },
                {
                  label: "User Reports",
                  href: "/user-report",
                  active: userreport,
                },
              ])}
              {renderDropdownMenu("GM007", "Reports", [
                { label: "Restaurant Reports", href: "/report", active: report },
                {
                  label: "Banner Reports",
                  href: "/admin-banner",
                  active: reports,
                },
               
                 {
                  label: "Cuisine Reports",
                  href: "/menu-report",
                  active: cuisine,
                },
                {
                  label: "Coupon Reports",
                  href: "/cupon-report",
                  active: cupon,
                },
                {
                  label: "Diet Reports",
                  href: "/diet-report",
                  active: diet,
                },
              ])}
              {renderDropdownMenu("GM008", "Administrative Settings", [
                { label: "Manage Cms", href: "/cms", active: cms },
                {
                  label: "Manage Country",
                  href: "/country",
                  active: country,
                },
               
                 {
                  label: "Manage Contact Us",
                  href: "/contact-us-list",
                  active: contactUs,
                },
                
              ])}
            </nav>
          </aside>
        </div>

        <div className="Content">
          <div className="child">{props.children}</div>
        </div>
      </div>
    </>
  );

  function renderDropdownMenu(id, title, items) {
    return (
      <div className="drop_down position-relative">
        <Link
          to={""}
          className="nav-link text-white"
          onClick={() => toggleMenu(id)}
          role="button"
          aria-expanded={openMenu === id}
        >
          <i className={`fa fa-${getIconForTitle(title)} picon`} />
          {title}
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </Link>

        <div className={`collapse ${openMenu === id ? "show" : ""}`} id={id}>
          <ul className="menu-aside">
            {items.map((item, index) => (
              <li className="menu-item" key={index}>
                <NavLink
                  activeclassname="active "
                  className={`menu-link ${item?.active}`}
                  to={item.href}
                  exact={true}
                >
                  <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                  <span className="text"> {item.label}</span>
                </NavLink>
              </li>
              
            ))}
          </ul>
        </div>
      </div>
    );
  }
  function getIconForTitle(title) {
    switch (title) {
      case "Restaurant Management":
        return "utensils";
      case "Claims":
        return "exclamation-circle";
      case "Users and Feedback":
        return "user-friends";
        case "Products and Ingredients":
        return "shopping-cart";
        case "Marketing and Promotions":
          return "thumb-tack";
          case "Reports":
            return "bug";
      default:
        return "cogs";
    }
  }
};

export default SidebarLayout;
