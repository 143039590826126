import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const Authenticate = ({ children }) => {
  const navigate = useNavigate();
  const token = (localStorage.getItem("token"))


useEffect(() => {
  if (!token) {
    navigate('/')
 }
}, [token]);

  return <>{children}</>;
};
export default Authenticate;
